export default class MediaManagerEntity {
  static get entity() {
    return {
      blob: null,
      dataUrl: null,
      size: null,
      total: 0,
      type: "image/jpeg",
      isImage: true,
      title: null,
      meta: null,
      link: null,
      dataLink: null,
      width: 0,
      height: 0,
      imageWidth: null,
      imageHeight: null,
      active: false,
      newTarget: false,
      tagId: null,
      tag: null,
      id: null,
      extension: ".jpg",
      creationTime: "0001-01-01T00:00:00",
      creationTimeUtc: "0001-01-01T00:00:00",
      lastAccesTime: "0001-01-01T00:00:00",
      lastAccessTimeUtc: "0001-01-01T00:00:00",
      lastWriteTime: "0001-01-01T00:00:00",
      lastWriteTimeUtc: "0001-01-01T00:00:00",
      name: null,
      path: null,
      url: null,
      base64String: null,
      stream: null,
      content: null,
      fileAttributes: 0,
      dataStringFormat: "Jpg",
      mimeType: "image/jpeg",
      roles: null
    };
  }

  static getEntity(url, name, extension, tag) {
    var entity = this.entity;
    entity.url = url;
    entity.name = name;
    entity.extension = extension;
    entity.tag = tag;
    return entity;
  }

  static toMobileUrl(src) {
    var ext = src.slice((src.lastIndexOf(".") - 1 >>> 0) + 2);
    var split = src.split('.' + ext);
    return split[0] + '_mobile.' + ext;
  }

  static getOriginalCaseFileExtension(src) {
    var ext = src.slice((src.lastIndexOf(".") - 1 >>> 0) + 2);
    return '.' + ext;
  }

  static getFileExtension(src) {
    var ext = this.getOriginalCaseFileExtension(src);
    return ext.toLowerCase();
  }

  static toMobileName(name) {
    var ext = this.getOriginalCaseFileExtension(name);
    name = name.replace(ext, '');
    return name + '_mobile' + ext;
  }

}