/* delegates the execution of toggle and getContent methods of autonomous react components in the DOM by another autonomous react component in the DOM. This allows a 
   bottom page toolbar to toggle the state of arbitrarily placed react components in the DOM
*/
class PageDelegate {
  static add(f) {
    this.contentFunctions.push(f);
  }

  static addToggle(f) {
    this.toggleFunctions.push(f);
  }

  static addResetToggle(f) {
    this.resetToggleFunctions.push(f);
  }

  static getContent() {
    var content = {};
    this.contentFunctions.forEach(f => {
      var c = f();
      content[c.key] = c.content;
    });
    return content;
  }

  static toggle() {
    this.toggleFunctions.forEach(f => f());
  }

  static resetToggle() {
    this.resetToggleFunctions.forEach(f => f());
  }

}

PageDelegate.contentFunctions = [];
PageDelegate.toggleFunctions = [];
PageDelegate.resetToggleFunctions = [];
export default PageDelegate;