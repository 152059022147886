import React from 'react';
export class RawMarkupComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  rawMarkup(body) {
    return {
      __html: body
    };
  }

}
export class UtilityComponent extends RawMarkupComponent {
  constructor(props) {
    super(props);
  }

  get isTouch() {
    return 'ontouchend' in document;
  }

  get clickEvent() {
    return 'ontouchend' in document ? 'touchend' : 'click';
  }

  get viewport() {
    return {
      width: window.innerWidth,
      height: window.innerHeight
    };
  }

  get isTouchSize() {
    return window.innerWidth < 1025;
  }

  fireEvent(name, data) {
    var event = new Event(name, {
      bubbles: true,
      cancelable: true,
      composed: true
    });
    event.detail = data;
    document.dispatchEvent(event);
    return event;
  }

  formatCurrency(val) {
    val = parseFloat(val);
    val = val.toFixed(2);
    return val;
  }

  formatStringCurrency(val) {
    val = parseFloat(val);
    return '$' + val.toFixed(2).toString();
  }
  /**
   *  object utilities, clones new object
   */


  objectClone(obj) {
    var json = JSON.stringify(obj);
    return JSON.parse(json);
  }
  /**
   * array utility, returns a new array clone
   * @param {array} arr 
   */


  arrayClone(arr) {
    return arr.slice(0);
  }
  /**
   * array utility, find item by id
   * @param {array} arr 
   * @param {string} id 
   */


  arrayFindItemById(arr, id) {
    var length = arr.length;
    var result = null;

    for (var i = 0; i < length; i++) {
      if (arr[i]['id'] === id) {
        result = arr[i];
        break;
      }
    }

    return result;
  }
  /**
   * array utility, find index of item by id
   * @param {array} arr 
   * @param {string} id 
   */


  arrayFindIndexById(arr, id) {
    var index = -1;
    var len = arr.length;

    for (var i = 0; i < len; i++) {
      if (arr[i]['id'] === id) {
        index = i;
        break;
      }
    }

    return index;
  }
  /**
   * array utility, update existing item in array
   * @param {array} arr 
   * @param {object} item 
   */


  arrayUpdateItem(arr, item) {
    var clone = arr.slice(0);
    var index = this.arrayFindIndexById(item.id);
    clone[index] = item;
    return clone;
  }
  /**
   * array utility, update existing item in array by index
   * @param {array} arr 
   * @param {object} item 
   * @param {int} index 
   */


  arrayUpdateItemByIndex(arr, item, index) {
    var clone = arr.slice(0);
    clone[index] = item;
    return clone;
  }
  /**
   * array utility, delete item in array by id
   * @param {array} arr 
   * @param {string} id 
   */


  arrayDeleteItemById(arr, id) {
    var clone = arr.slice(0);
    var index = this.arrayFindIndexById(clone, id);

    if (index > -1) {
      clone.splice(index, 1);
    }

    return clone;
  }
  /**
   *  array utility, delete item in array by index
   * @param {array} arr 
   * @param {int} index 
   */


  arrayDeleteItemByIndex(arr, index) {
    var clone = arr.slice(0);
    clone.splice(index, 1);
    return clone;
  }
  /**
   * array utility, add item to array
   * @param {array} arr 
   * @param {object} item 
   */


  arrayAddItem(arr, item) {
    var clone = arr.slice(0);
    clone.push(item);
    return clone;
  }
  /**
   *  generates a random string
   */


  randomString(length) {
    if (!length) length = 16;
    let chars = '0123456789';
    let result = '';

    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];

    return result;
  }

  get randomId() {
    let length = 16;
    let chars = '0123456789';
    let result = '';

    for (var i = length; i > 0; --i) result += chars[Math.round(Math.random() * (chars.length - 1))];

    return result;
  }

  getDate(value) {
    value = value.split('T');
    value = value[0];
    value += 'T12:00:00';
    var date = new Date(value);
    var day = date.getDate().toString().length > 1 ? date.getDate() : '0' + date.getDate();
    var month = date.getMonth().toString().length > 1 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    var year = date.getFullYear();
    return year + '-' + month + '-' + day;
  }

}
export default {
  RawMarkupComponent,
  UtilityComponent
};