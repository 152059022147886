export default class {
  static write(msg) {
    if (typeof window !== 'undefined') {
      console.log(msg);
    }
  }

}
/*export const Console = (msg) => {
    if (typeof window !== 'undefined') {
        console.log(msg);
    }
}

export default Console*/