import React from 'react';
export default class LightGalleryComponent extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}

  componentWillUnmount() {
    if (this.$target) this.destroyLightGalleryPlugin();
    this.$target = null;
  }

  getLightGalleryData(media, name, max_width) {
    var arr = [];

    for (var i = 0; i < media.length; i++) {
      var title = media[i].title;
      if (!title) title = name;
      var item = {
        src: media[i].url + '?width=' + max_width,
        subHtml: '<span>' + title + '</span>'
      };
      arr.push(item);
    }

    return arr;
  }

  createLightGalleryPlugin($target, data, index) {
    this.$target = $target;
    $target.lightGallery({
      dynamic: true,
      dynamicEl: data,
      index: index
    });
  }

  destroyLightGalleryPlugin() {
    if (this.$target && this.$target.data) this.$target.data('lightGallery').destroy(true);
  }

}