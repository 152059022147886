import React from 'react';
import ReactDOM from 'react-dom';
import ReactDOMServer from 'react-dom/server'; //React Globals

global.React = React;
global.ReactDOM = ReactDOM;
global.ReactDOMServer = ReactDOMServer; //App Demo Components

import DemoComponent from './demo';
import TestComponent from './demo/test'; //Cms Components

import { HeroTitle, H2Title, H3Title, H4Title, H5Title, H1Title, BlockQuotation, ListEditor, ImageLink, Image, HeaderTitleComponent, HeroTitleComponent, H3TitleComponent, H4TitleComponent, H5TitleComponent, HTitleComponent, HeroComponent, HeroPictureComponent, HeroPictureTitleComponent, HeroImageComponent, HeroPictureLinkComponent, HeroImageLinkComponent, ImageComponent, HtmlEditorComponent, HeaderListComponent, HeroTitleListComponent, EditorListComponent, TinyEditorComponent, MinimalEditorComponent, ContentEditableComponent, TitleContentEditableComponent, TitleTinyEditorComponent, BlockQuoteComponent, VideoComponent, MultiSlideCarouselComponent } from '../library/cms/content-components';
import { PageEmbeddedApp, PageApp } from './page/apps';
import PagesSummaryApp from './page/summary'; //App Components

import HomeGrid from './home/grid';
import AboutGallery from './about/gallery';
import AboutCarousel from './about/carousel';
import ServicesGrid from './services';
import ProjectApp from './projects/app';
import CareersApp from './careers/app';
import { AwardsComponent, AwardsCarouselComponent, CatalogCarouselComponent, ReviewsComponent } from './press'; //bind to global

global.Components = {
  DemoComponent,
  TestComponent,
  HomeGrid,
  AboutGallery,
  AboutCarousel,
  ServicesGrid,
  ProjectApp,
  CareersApp,
  AwardsComponent,
  AwardsCarouselComponent,
  CatalogCarouselComponent,
  ReviewsComponent
};
global.Cms = {
  HeroTitle,
  H2Title,
  H3Title,
  H4Title,
  H5Title,
  H1Title,
  BlockQuotation,
  ListEditor,
  ImageLink,
  Image,
  HeaderTitleComponent,
  HeroTitleComponent,
  H3TitleComponent,
  H4TitleComponent,
  H5TitleComponent,
  HTitleComponent,
  HTitleComponent,
  HeroComponent,
  HeroPictureComponent,
  HeroImageComponent,
  HeroPictureLinkComponent,
  HeroPictureTitleComponent,
  HeroImageLinkComponent,
  ImageComponent,
  HtmlEditorComponent,
  HeaderListComponent,
  HeroTitleListComponent,
  EditorListComponent,
  TinyEditorComponent,
  MinimalEditorComponent,
  ContentEditableComponent,
  TitleContentEditableComponent,
  TitleTinyEditorComponent,
  BlockQuoteComponent,
  VideoComponent,
  MultiSlideCarouselComponent,
  PageEmbeddedApp,
  PageApp,
  PagesSummaryApp
};
global.serviceRootEndpoint = '/api';