import Service from '../plunk/service/service';
import PubSub from 'pubsub-js';
import string from '../utils/string';
export default class ServiceFactory {
  static getType(endpoint) {
    if (!endpoint) endpoint = '';

    if (string.firstChar(endpoint) !== '/') {
      endpoint = '/' + endpoint;
    }

    class FService extends Service {}

    ;

    if (global.serviceRootEndpoint) {
      endpoint = global.serviceRootEndpoint + endpoint;
    }

    FService.rootEndpoint = endpoint;

    if (global && global.serviceHeaders) {
      FService.headers = global.serviceHeaders;
    }

    FService.onAuthTokenFailure = (err, callback) => {
      callback(err, null);
      FService.removeTokens(); //location.href = '/Auth/SignIn';

      PubSub.publish('token-failure', err);
    };

    return FService;
  }

}